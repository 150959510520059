import { css, html, LitElement, nothing } from "lit";
import {
  checkmark,
  circleSlashIcon,
  chargerIncluded,
  chargerNotIncluded,
  chargerForText,
} from "../../utils/svgProvider.js";

class ChargerInfo extends LitElement {
  static properties = {
    _show: { state: true },
    _included: { state: true },
    _powerDelivery: { state: true },
    _wattRange: { state: true },
    data: { type: Object },
    translations: { type: Object },
  };

  constructor() {
    super();
    this._show = false;
  }

  updated(changedProperties) {
    if (changedProperties.has("data")) {
      this._included = this.data["included"];
      this._powerDelivery = this.data["powerDelivery"];
      this._wattRange = (this.data["wattRange"] || "").replaceAll(" ", "");
      this._show =
        this.data.hasOwnProperty("included") &&
        this.data.hasOwnProperty("powerDelivery") &&
        this.data.hasOwnProperty("wattRange");
    }
  }

  render() {
    return this._show
      ? html`
          <div class="container">
            <div class="left-section">
              ${this._included ? checkmark : circleSlashIcon}
              <div>
                ${this.translations[
                  this._included ? "included" : "notIncluded"
                ]}
              </div>
            </div>
            <div class="right-section">
              ${this._included ? chargerIncluded : chargerNotIncluded}
              <div class="charger-with-details">
                ${chargerForText}
                <div class="details">
                  <div>${this._wattRange}</div>
                  <div>W</div>
                  ${this._powerDelivery ? html`<div>USB PD</div>` : nothing}
                </div>
              </div>
            </div>
          </div>
        `
      : nothing;
  }

  static styles = css`
    :host {
      display: block;
    }

    .container {
      display: flex;
      justify-content: space-between;
      background-color: #f7f7f7;
      border-radius: 4px;
      padding: 12px 16px;
      font-size: 14px;
      line-height: 18px;
      color: #222;
      gap: 12px;
    }

    .left-section {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    .left-section svg {
      width: 20px;
      height: 20px;
      flex-shrink: 0;
    }

    .right-section {
      display: flex;
      gap: 4px;
    }

    .right-section > svg {
      height: 62px;
      width: auto;
    }

    .charger-with-details {
      position: relative;
      display: flex;
      align-items: center;
    }

    .charger-with-details > svg {
      height: 60px;
      width: auto;
    }

    .details {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      inset: 20px 2px 0px;
      font-size: 10px;
      line-height: 1.2;
      justify-content: center;
      font-weight: 500;
    }
  `;
}

customElements.define("komplett-charger-info", ChargerInfo);
